import styled from '@emotion/styled';
import { DocumentType, ModelType } from '@innedit/innedit-type';
import classnames from 'classnames';
import objectHash from 'object-hash';
import React, { ReactElement, ReactNode, SyntheticEvent, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import Button, { ButtonProps } from '../../../components/Button';
import ButtonEL from '../../../components/Button/styles';
import IconEdit from '../../../icons/Edit';
import IconSort from '../../../icons/Sort';
import { colors, screens } from '../../../styles/theme';

const CardTypes = {
  ITEM: 'item',
};

interface DragProduit {
  index: number;
  type: string;
}

interface CardProps<T> {
  actions?: ButtonProps | ButtonProps[];
  actionsClassName?: string;
  className?: string;
  displayEdit?: boolean;
  displayId?: boolean;
  displayOrder?: boolean;
  doc: DocumentType<T>;
  changePosition?: (oldIndex: number, newIndex: number) => void;
  index?: number;
  onClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
}

const CardSC = styled.li`
  position: relative;
  .actions {
    display: flex;
    justify-content: space-around;
  }

  @media (min-width: ${screens.md}) {
    .actions {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: none;
      background: ${colors.light[500]};
    }
  }

  &:hover {
    background: ${colors.light[500]};
    .actions {
      display: flex;
    }
  }
`;

const ListCard = <T extends ModelType>({
  actions,
  changePosition,
  children,
  displayEdit = true,
  displayId = true,
  displayOrder = true,
  doc,
  index,
  onClick,
}: CardProps<T> & {
  children?: ReactNode | undefined;
}): ReactElement | null => {
  const ref = useRef<HTMLLIElement>(null);

  const [{ isOver }, drop] = useDrop({
    accept: CardTypes.ITEM,
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
    }),
    drop(item: DragProduit) {
      if (!ref.current || undefined === index) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      if (changePosition) {
        changePosition(dragIndex, hoverIndex);
      }
    },
  });

  const [{ isDragging }, drag, previous] = useDrag({
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    item: { index, type: CardTypes.ITEM },
    type: CardTypes.ITEM,
  });

  if (changePosition) {
    previous(drop(ref));
  }

  return (
    <CardSC
      ref={ref}
      className={classnames(
        'flex flex-col justify-between rounded border p-0',
        {
          'border-gray-400': isOver,
          'opacity-40': isDragging,
        },
      )}
    >
      <div className="flex flex-auto flex-col rounded">{children}</div>
      <div className="actions rounded-b p-0.5">
        {actions &&
          (!Array.isArray(actions) ? [actions] : actions).map(
            ({ ...props }) => {
              const { text, ...actionProps } = props;

              return (
                <Button
                  key={doc.id + objectHash(props)}
                  text={text}
                  variant="card"
                  {...actionProps}
                />
              );
            },
          )}
        {doc.id && displayId && (
          <Button
            className="no-left-radius"
            tooltip={doc.id}
            type="tooltip"
            variant="card"
          />
        )}

        {displayOrder && changePosition && (
          <ButtonEL ref={drag} variant="card">
            <IconSort />
          </ButtonEL>
        )}

        {displayEdit && onClick && (
          <Button
            data-id={doc.id}
            iconLeft={IconEdit}
            onClick={onClick}
            variant="card"
          />
        )}
      </div>
    </CardSC>
  );
};

export default ListCard;
